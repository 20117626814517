import { I18n } from '@front/volcanion'

import FormatUtils from '@front/squirtle/utils/format'
import errors from '@front/volcanion/classes/error'

import { OrangeContact } from '../..'

class Callbacks {
  static recordToFormHandler() {
    return function recordToForm(record) {
      const options = _.map(_.groupBy(_.get(record, 'options'), 'categoryoption'), (elem, key) => ({
        [key]: _.map(elem, 'user_option_id')
      }))

      return {
        status: FormatUtils.getStatus(_.pick(_.get(record, 'auth'), ['active', 'deleted', 'suspendedUntil', 'suspendedSince'])),
        auth: {
          email: _.get(record, 'auth.email'),
          gsm: _.get(record, 'auth.gsm'),
          gsm_verified: _.get(record, 'auth.gsm_verified')
        },
        info: {
          user_client_id: _.get(record, 'info.user_client_id'),
          first_name: _.get(record, 'info.first_name'),
          last_name: _.get(record, 'info.last_name'),
          createdAt: _.get(record, 'info.createdAt'),
          updatedAt: _.get(record, 'info.updatedAt'),
          birth_date: _.get(record, 'info.birth_date'),
          birth_place: _.get(record, 'info.birth_place'),
          nationality: _.get(record, 'info.nationality'),
          address: _.get(record, 'info.address'),
          tel: _.get(record, 'info.tel')
        },
        score: {
          average_rating: _.get(record, 'score.average_rating')
        },
        driverinfo: {
          app_version: _.get(record, 'driverinfo.app_version'),
          driver_license: _.get(record, 'driverinfo.driver_license'),
          id_card: _.get(record, 'driverinfo.id_card'),
          capacity_certif: _.get(record, 'driverinfo.capacity_certif'),
          can_call_booker: record?.driverinfo?.can_call_booker
        },
        options: _.assign({}, ...options),
        payment_types: !_.isEmpty(_.get(record, 'payment_types')) ? _.map(_.get(record, 'payment_types'), 'paymenttype_id') : undefined,
        heatmap_blacklist: record?.heatmap_blacklist
      }
    }
  }

  static getEmptyFormHandler() {
    return function getEmptyFormValues() {
      return {
        status: 'active'
      }
    }
  }

  static formToRecordHandler(CODE_INTERNATIONAL) {
    return function formToRecord(values, extra, meta, { sim_number, suspended, ...state }) {
      return {
        status: FormatUtils.parseStatus(_.get(values, 'status'), _.pick(suspended, ['suspendedUntil', 'suspendedSince', 'motive'])),
        auth: {
          email: _.get(values, 'auth.email'),
          gsm: FormatUtils.parsePhoneNumber(_.get(values, 'auth.gsm'), CODE_INTERNATIONAL),
          gsm_verified: _.get(values, 'auth.gsm_verified')
        },
        info: {
          first_name: _.get(values, 'info.first_name'),
          last_name: _.get(values, 'info.last_name'),
          birth_date: _.get(values, 'info.birth_date'),
          birth_place: _.get(values, 'info.birth_place'),
          nationality: _.get(values, 'info.nationality'),
          address: _.get(values, 'info.address'),
          tel: _.get(values, 'info.tel')
        },
        driverinfo: {
          sim_number: sim_number || null,
          driver_license: _.get(values, 'driverinfo.driver_license'),
          id_card: _.get(values, 'driverinfo.id_card'),
          capacity_certif: _.get(values, 'driverinfo.capacity_certif'),
          can_call_booker: values?.driverinfo?.can_call_booker
        },
        options: _.has(values, 'options') ? _.compact(_.flattenDeep(_.values(_.get(values, 'options')))) : undefined,
        type: 'driver',
        payment_types: !!_.has(values, 'payment_types')
          ? _.map(_.get(values, 'payment_types'))
          : undefined,
        heatmap_blacklist: values?.heatmap_blacklist
      }
    }
  }

  static recordToStateHandler() {
    return function recordToState(record) {
      return {
        hasPassword: !!record?.auth?.password_updated_at,
        hasGsm: !!record?.auth?.gsm,
        sim_number: _.get(record, 'driverinfo.sim_number'),
        record_id: _.get(record, 'user_id'),
        title: _.get(record, 'info.user_client_id'),
        suspended: {
          suspendedUntil: record?.auth?.suspendedUntil,
          suspendedSince: record?.auth?.suspendedSince,
          motive: record?.auth?.motive
        }
      }
    }
  }

  static onSubmitSuccessHandler() {
    return async function onSubmitSuccess(result, values, extra, meta) {
      console.log("🚀 ~ file: callbacks.js ~ line 27 ~ Callbacks ~ onSubmitSuccess ~ result, values, extra, meta", result, values, extra, meta)
    }
  }
  static onSubmitFailedHandler(openNotification, openDialog) {
    return async function onSubmitFailed(err, values, extra, meta) {
      if (!(err instanceof errors.MismatchError)) return
      if (err.getCode() === 'DRIVER_CONTRACT_LIMIT')
        return openNotification(I18n.t('driver.driver_contract.error.driver_contract_limit', { driver_contract_limit: err.context?.driver_contract_limit }), { variant: 'error' })
    }
  }

  static handleOrangeCallHandler() {
    return async function handleOrangeCall(phoneNumber) {
      return OrangeContact.execute('GET', 'clickToCall', { phoneNumber })
    }
  }

  static handleCallHandler(EXTERNAL_PHONE_SERVICE, orangeCall) {
    return async function call(number) {
      if (!number) return
      if (EXTERNAL_PHONE_SERVICE === 'aws') {
        const endpoint = connect.Endpoint.byPhoneNumber(number)
        const agent = new connect.Agent()
        const contacts = agent.getContacts()
        if (_.isEmpty(contacts))
          agent.connect(endpoint)
        else if (contacts.length === 1)
          contacts[0].addConnection(endpoint)
      }
      else if (EXTERNAL_PHONE_SERVICE === 'orange') {
        const phoneNumber = encodeURIComponent(number)
        await orangeCall(phoneNumber)
      }
    }
  }

  static onKeyDownHandler(gsm, handleCall) {
    return async function onKeyDownHandler(event) {
      const { key, altKey, ctrlKey } = event

      if (!!altKey || !!ctrlKey) return

      switch (key) {
        case 'F7':
          event.preventDefault()
          await handleCall(gsm)
          break;
        default:
      }
    }
  }

  static confirmResetHandler(openDialog, user_client_id, user_id) {
    return async function confirmReset(method) {
      openDialog('confirm_reset_password', {
        title: I18n.t('password.reset.confirm.title'),
        description: I18n.t('password.reset.confirm.driver', { user_client_id, method }),
      }, { method, user_id }, { method, user_id }
      )
    }
  }
  static onResetSmsPasswordHandler(confirmReset) {
    return async function onResetSmsPassword() {
      confirmReset('sms')
    }
  }
  static onResetEmailPasswordHandler(confirmReset) {
    return async function onResetEmailPassword() {
      confirmReset('email')
    }
  }

  static onCallDriverHandler(openDialog, user_client_id, first_name, last_name) {
    return function onCallDriver() {
      openDialog('confirm_call_driver', {
        title: I18n.t('telephony.toast.call_driver.title'),
        description: I18n.t('telephony.toast.call_driver.description', { user_client_id, first_name, last_name }),
      })
    }
  }
  static formToOptionsHandler() {
    return function formToOptions(values, extra, meta, state) {
      return {
        single: true,
      }
    }
  }
  static beforeSubmitHanbdler(openDialog, job_ids) {
    return function beforeSubmit(values, extra, meta, state) {
      const statusMeta = _.find(meta, ['field', 'status'])

      if (
        !_.isEmpty(job_ids) &&
        !extra?.forceSubmit &&
        !!statusMeta?.dirty &&
        _.includes(['deleted', 'suspended'], values?.status)
      ) {
        openDialog('show_active_jobs', {
          title: I18n.t('driver.popup.show_active_jobs.title'),
          description: I18n.t('driver.popup.show_active_jobs.description'),
          job_ids,
        })
        return false
      }
      return true
    }
  }
}

export default Callbacks
